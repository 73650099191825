<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
    </div>
    <div v-else-if="matchSocial.length == 0 && is_data_ready">
        <p>{{ $t("No social commentary available") }}</p>
    </div>
    <div v-else>
        <div class="commentary">
            <div class="rllvc_commentary_list_wrap">
                <ul :key="index" v-for="(social, index) in matchSocial">
                    <li class="rllvc_twiiter_commentary clearfix">
                        <div class="twitter_body_area">
                            <img :src=social.media_image class="img-responsive rllvc_commentry_img lazy" alt="rllvc commentry img" loading="lazy" width="1232" height="1232">
                            <span class="rllvc_twitter_icon">
                                <a :href="(social.author_pageurl+'/status/'+social.provider_id)"
                                   class="rllvc_twitter_commentary"
                                   target="blank" title="Share">
                                </a>
                            </span>
                            <div class="rllvc_twitter_slogun_block">
                                <a :href="social.author_pageurl" class="salogun_pic" target="_blank" width="40" height="40" aria-label="author profile image">
                                    <img :src="social.author_profile_image" alt="author profile image" width="40" height="40" class="lazy" loading="lazy" >
                                </a>
                                <div class="rllvc_twitter_slogun_content">
                                    <strong>
                                        <a :href="social.author_pageurl"
                                           target="_blank">{{social.author_name}}</a>
                                    </strong>
                                </div>
                            </div>
                            <div class="rllvc_twiiter_content" v-html="decodeHtml(social.comment)"></div>
                        </div>
                        <div class="rllvc_twitter_footer_area">
                            <table class="rllvc_commentary_minute_icon">
                                <tbody>
                                <tr>
                                    <td>
                                        <span class="rllvc_commentary-date">{{ social.date  }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="rllvc_twiiter_footer_icon">
                            <span>
                                <a :href="('https://twitter.com/intent/tweet?text='+social.comment)"
                                   class="rllvc_share_commentary"
                                   target="blank" title="Share">
                                </a>
                            </span>
                            <span>
                                <a :href="('https://twitter.com/intent/like?tweet_id='+social.provider_id)"
                                   class="rllvc_fav_commentary"
                                   target="blank" title="Like">
                                </a>
                            </span>
                            <span>
                                <a :href="('https://twitter.com/intent/like?tweet_id='+social.provider_id)"
                                   class="rllvc_repeat_commentary" target="blank"
                                   title="Retweet">
                                </a>
                            </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "social",

        data() {
            return {
                matchSocial: null,
            }
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_social_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_social_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_social_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        serverPrefetch() {
            return this.getSocial();
        },
        mounted() {
            this.getSocial()
        },
        methods: {
            getSocial() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_SOCIAL_API.replace(':match', this.$route.params.slug))
                    .then(response => {
                        if (response.data) {
                            this.matchSocial = response.data.result.cricGalleries;
                        }
                        this.is_data_ready = true;
                    })
            },
            decodeHtml(html) {
                if(isBrowser) {
                    var txt = document.createElement("textarea");
                    txt.innerHTML = html;
                    return txt.value;
                } else {
                    return html
                }
            }
        },

    }
</script>
